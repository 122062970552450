import { FieldName, useField, useInputControl } from "@conform-to/react";
import React, { useId } from "react";
import { REGEXP_ONLY_DIGITS, type OTPInputProps } from "input-otp";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "./ui/input-otp.tsx";
import { Checkbox, type CheckboxProps } from "./ui/checkbox.tsx";
import { Input } from "./ui/input.tsx";
import { Label } from "./ui/label.tsx";
import { Textarea } from "./ui/textarea.tsx";
import { Icon } from "./ui/icon.tsx";
import { cn } from "#app/utils/misc.tsx";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "./ui/select";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors;
	id?: string;
}) {
	const errorsToRender = errors?.filter(Boolean);
	if (!errorsToRender?.length) return null;
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map((e) => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	);
}

export function Field({
	labelProps,
	inputProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	inputProps: React.InputHTMLAttributes<HTMLInputElement>;
	buttonProps?: {
		props: React.ButtonHTMLAttributes<HTMLButtonElement>;
		content?: React.ReactNode;
	};
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const id = inputProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<div className="relative">
				<Input
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					{...inputProps}
				/>
				{buttonProps && (
					<button
						{...buttonProps.props}
						type="button"
						className={cn(
							"absolute right-0 top-2",
							buttonProps.props.className,
						)}
					>
						{buttonProps.content}
					</button>
				)}
			</div>
			<div className={`${errorId ? "min-h-[32px]" : ""} px-4 pt-1 pb-3`}>
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

type SelectFieldProps = {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	selectProps: {
		name: string;
		id: string;
		value?: string;
		placeholder?: string;
		autoFocus?: boolean;
		onChange: (value: string) => void;
	};
	options: { value: string; label: string }[];
	errors?: ListOfErrors;
};

export const SelectField: React.FC<SelectFieldProps> = ({
	labelProps,
	selectProps,
	options,
	errors,
}) => {
	const fallbackId = useId();
	const id = selectProps.id ?? fallbackId;
	const placeholder = selectProps.placeholder ?? "Select an option";
	const autoFocus = selectProps.autoFocus ?? false;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div>
			<Label {...labelProps} />
			<Select
				value={selectProps.value}
				onValueChange={(value) => selectProps.onChange(value)}
			>
				<SelectTrigger id={selectProps.name} autoFocus={autoFocus}>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					{options.map((option) => (
						<SelectItem key={option.value} value={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			<div className={`${errorId ? "min-h-[32px]" : ""} px-4 pt-1 pb-3`}>
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
};

export function PasswordField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	inputProps: React.InputHTMLAttributes<HTMLInputElement>;
	errors?: ListOfErrors;
	className?: string;
}) {
	const [showPassword, setShowPassword] = React.useState(false);
	const toggleShowPassword = () => setShowPassword((prev) => !prev);

	const customInputProps = {
		...inputProps,
		type: showPassword ? "text" : "password",
	};

	return (
		<Field
			className={className}
			labelProps={labelProps}
			inputProps={customInputProps}
			buttonProps={{
				props: {
					onClick: toggleShowPassword,
					className: "absolute bottom-10 right-3",
					"aria-label": showPassword ? "Hide password" : "Show password",
				},
				content: showPassword ? (
					<Icon name="eye" size="md" />
				) : (
					<Icon name="eye-off" size="md" />
				),
			}}
			errors={errors}
		/>
	);
}

export function OTPField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	inputProps: Partial<OTPInputProps & { render: never }>;
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const id = inputProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className={`${errorId ? "min-h-[32px]" : ""} px-4 pt-1 pb-3`}>
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}
export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			<div className={`${errorId ? "min-h-[32px]" : ""} px-4 pt-1 pb-3`}>
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements["label"];
	buttonProps: CheckboxProps & {
		name: string;
		form: string;
		value?: string;
	};
	errors?: ListOfErrors;
	className?: string;
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps;
	const fallbackId = useId();
	const checkedValue = buttonProps.value ?? "on";
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	});
	const id = buttonProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={(state) => {
						input.change(state.valueOf() ? checkedValue : "");
						buttonProps.onCheckedChange?.(state);
					}}
					onFocus={(event) => {
						input.focus();
						buttonProps.onFocus?.(event);
					}}
					onBlur={(event) => {
						input.blur();
						buttonProps.onBlur?.(event);
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className={`${errorId ? "min-h-[32px]" : ""} px-4 pt-1 pb-3`}>
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

type HiddenFieldsProps = {
	data: Record<string, string | number>;
};

export const HiddenFields: React.FC<HiddenFieldsProps> = ({ data }) => {
	return (
		<React.Fragment>
			{Object.entries(data).map(([name, value]) => (
				<input
					key={name}
					type="hidden"
					name={name}
					defaultValue={String(value)}
				/>
			))}
		</React.Fragment>
	);
};
